import classNames from "classnames"
import {
  Matchday,
  matchdaysMannschaft1Halle2324,
  MiscMatch,
  vorsfelde,
} from "data/matchdays"
import moment from "moment"
import * as React from "react"

interface MatchdaysManualProps {
  team: string
  season: string
  matchdays: Matchday[]
}

interface MiscMatchesProps {
  team: string
  season: string
  matchdays: MiscMatch[]
}

const MatchdaysManual = () => (
  <>
    <MatchdaysManualComponent
      team="1. Mannschaft"
      season="Halle 2023/24"
      matchdays={matchdaysMannschaft1Halle2324}
    />
    {/* <MatchdaysManualComponent
      team="2. Mannschaft"
      season="Feld 2023"
      matchdays={matchdaysMannschaft2Feld23}
    /> */}
    {/* <MiscMatchesComponent
      team="Männer 35"
      season="Halle 2022/23"
      matchdays={miscMatchesMaenner35}
    /> */}
  </>
)

const MiscMatchesComponent = (props: MiscMatchesProps) => (
  <>
    <h3 className="h3 mb-3 mt-10">
      Termin {props.team}{" "}
      <small className="inline-flex items-center whitespace-nowrap rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800 dark:bg-green-800 dark:text-green-100">
        {props.season}
      </small>
    </h3>
    <table className="table">
      <thead className="thead">
        <tr>
          <th scope="col" className="th">
            Datum
          </th>
          <th scope="col" className="th">
            Turnier
          </th>
          <th scope="col" className="th">
            Ausrichter
          </th>
        </tr>
      </thead>
      <tbody className="tbody">
        {props.matchdays.map((matchday, idx) => (
          <tr key={idx}>
            <td
              className={classNames("td", {
                "bg-primary-light font-bold text-gray-200 dark:bg-primary":
                  matchday.host === vorsfelde,
              })}
            >
              {moment(matchday.dateFrom).format("DD.MM.YYYY")}
              {matchday.dateTo !== undefined && (
                <> - {moment(matchday.dateTo).format("DD.MM.YYYY")}</>
              )}
            </td>
            <td
              className={classNames("td", {
                "bg-primary-light font-bold text-gray-200 dark:bg-primary":
                  matchday.host === vorsfelde,
              })}
            >
              {matchday.name}
            </td>
            <td
              className={classNames("td hidden lg:table-cell", {
                "bg-primary-light font-bold text-gray-200 dark:bg-primary":
                  matchday.host === vorsfelde,
              })}
            >
              {matchday.host}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
)

const MatchdaysManualComponent = (props: MatchdaysManualProps) => (
  <>
    <h3 className="h3 mb-3 mt-10">
      Spieltage {props.team}{" "}
      <small className="inline-flex items-center whitespace-nowrap rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800 dark:bg-green-800 dark:text-green-100">
        {props.season}
      </small>
    </h3>
    {/* <div className="w-full table-auto overflow-scroll"> */}
    <div className="">
      <table className="table">
        <thead className="thead">
          <tr>
            <th scope="col" className="th">
              Datum
            </th>
            <th scope="col" className="th">
              Gegner
            </th>
            <th scope="col" className="th hidden lg:table-cell">
              Ausrichter
            </th>
          </tr>
        </thead>
        <tbody className="tbody">
          {props.matchdays.map((matchday, idx) => (
            <tr key={idx}>
              <td
                className={classNames("td", {
                  "bg-primary-light font-bold text-gray-200 dark:bg-primary":
                    matchday.host === vorsfelde,
                })}
              >
                <span className="hidden sm:inline">
                  {moment(matchday.date).format("DD.MM.YYYY HH:mm")}
                </span>
                <span className="inline sm:hidden">
                  {moment(matchday.date).format("DD.MM.YYYY")}
                  <br />
                  {moment(matchday.date).format("HH:mm")}
                </span>
              </td>
              <td
                className={classNames("td", {
                  "bg-primary-light font-bold text-gray-200 dark:bg-primary":
                    matchday.host === vorsfelde,
                })}
              >
                <span>
                  {matchday.enemies.map(enemy => (
                    <span key={enemy}>
                      {enemy}
                      <br />
                    </span>
                  ))}
                </span>
              </td>
              <td
                className={classNames("td hidden lg:table-cell", {
                  "bg-primary-light font-bold text-gray-200 dark:bg-primary":
                    matchday.host === vorsfelde,
                })}
              >
                {matchday.host}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
)

export default MatchdaysManual
